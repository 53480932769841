<template>
      <div class='tablebox'>
            <div class="tableHeight">
                  <vxe-table border="inner" :data="tableData" align="center" round :cell-class-name="cellClassName">
                        <vxe-table-column field="row" title="班级排行">
                              <template #default="{ row }">
                                    <img :src="rankingImg[row.row]" v-if="row.row<4" class="head">
                                    <span v-else>{{row.row}}</span>
                              </template>
                        </vxe-table-column>
                        <vxe-table-column field="userName" title="姓名">
                              <template #default="{ row }">
                                    <div class="director">
                                          <img
                                                :src="row.avatar?'https://test.lezhireading.com/uploads/'+row.avatar:head">
                                          <span>{{row.userName}}</span>
                                    </div>
                              </template>
                        </vxe-table-column>
                        <vxe-table-column field="areaRow" title="区域排名"></vxe-table-column>
                        <vxe-table-column field="score" title="得分"></vxe-table-column>
                        <template v-slot:empty>
                              <span>
                                    <img src="../../../assets/empty.png" class="emptyImg">
                                    <p class="emptyText">期待同学们上传作品~</p>
                              </span>
                        </template>
                  </vxe-table>
            </div>
            <!-- <div class="pag flex-align-center">
                  <vxe-pager background :current-page.sync="page5.currentPage" :page-size.sync="page5.pageSize"
                        :total="page5.totalResult"
                        :layouts="['PrevPage', 'JumpNumber', 'NextPage','Total', 'FullJump']">
                  </vxe-pager>
            </div> -->
      </div>
</template>

<script>
      export default {
            data() {
                  return {
                        rankingImg: {
                              '1': require("../../../assets/myactivity/first.png"),
                              '2': require("../../../assets/myactivity/econd.png"),
                              '3': require("../../../assets/myactivity/thirdly.png"),
                        },
                        page5: {
                              currentPage: 1,
                              pageSize: 10,
                              totalResult: 7
                        },
                        tableData: [{
                              avatar: '',
                              row: '',
                              score: '',
                              userName: '',
                              areaRow: ''

                        }],
                        head: require("../../../assets/myactivity/stu-boy.png"),
                  }
            },
            props: ['value', 'activityId'],
            //方法
            methods: {
                  async getstudentRank() {
                        let data = {
                              activityId: this.activityId,
                              classId: this.value,
                        }
                        let resData = await this.$Api.Myactivity.getstudentRank(data);
                        this.tableData = resData.data;
                        if (this.tableData.length == 0) return false;
                        // this.tableData.sort((a,b)=>{
                        //       return a.row-b.row
                        // })
                        // console.log('学生排行', resData);
                  },
                  cellClassName({
                        column,
                  }) {
                        if (column.property === 'classRanking' || column.property === 'Regional' || column.property ===
                              'score') {
                              return 'col-red'
                        }
                  },
            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('table1', this.value)
                  this.getstudentRank();
            },
            watch: {
                  value(newVal, oldVal) {
                        this.getstudentRank();
                        // console.log('oldVal', oldVal)
                        // console.log('newVal', newVal)
                  }
            }
      }
</script>
<style lang='less' scoped>
      @import "./less/table";
</style>