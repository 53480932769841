<template>
  <div class="activity flex-column">
    <div class="Header flex-align-between">
      <div class="entrance">
        <div class="head flex-align">
          <img :src="avatar1" v-if="succeed">
          <img :src="require('../../assets/homepage/tou.png')" v-else>
        </div>
        <div class="type">
          <ul class="flex-between">
            <li class="flex-column-center" v-for="(item,index) in tackclass" :key="index">
              <img :src="item.imgs" @click="site(item.name)">
              <span>{{item.name}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="back flex-align-between">
        <img :src="require('../../assets/task/return.png')" @click="goback()">
        <span @click="goback()">返回</span>
      </div>
    </div>
    <div class="Main flex-align-center">
      <div class="activitiesCover flex-align-between" v-if="Cover">
        <img src="../../assets/myactivity/left.png" @click="slidePre" />
        <div class="activityType">
          <slider ref="slider" :options="options" @slide='slide'>
            <slideritem v-for="(item,index) in CoverData" :key="index">
              <div class="CoverBox flex-align-center" :style="{backgroundImage: 'url(' + item.cover + ')'}">
                <div class="btn">
                  <span @click="activityCut(0,item.id)">班级作品</span>
                  <span @click="activityCut(1,item.id)">排行榜</span>
                </div>
              </div>
            </slideritem>
            <div slot="loading">loading...</div>
          </slider>

        </div>
        <img src="../../assets/myactivity/right.png" @click="slideNext" />

        <!-- :style="{backgroundImage: 'url(' + CoverData.cover + ')'}" -->
      </div>
      <div class="activitiesWork" v-else>
        <div class="workList" v-if="activityStatus =='0'">
          <Sample :category='category' :activityId='activityId'></Sample>

        </div>
        <div class="rankingList flex-column" v-if="activityStatus == '1'">
          <div class="rankingCut flex-align-center">
            <div @click="student" :class="{'sign':signShow}">学生排名</div>
            <div @click="classran" :class="{'sign':!signShow}">班级排名</div>
          </div>
          <div class="rankingTable flex-align-center">
            <div class="Table">
              <Table v-if="signShow" :value='classId' :activityId='activityId'></Table>
              <Table2 v-else :value='classId' :activityId='activityId' :className="value"></Table2>
            </div>
          </div>
          <div class="logoBox flex-align-center">
            <img class="logo" :src='require("../../assets/myactivity/logo.png")'>
          </div>
          <div class="selectBox flex-align-between">
            <div class="className">选择班级：</div>
            <div class="Sizer">
              <vxe-select v-model="value" placeholder="默认尺寸" @change='classChange'>
                <vxe-option v-for="(num,index) in selectList" :key="index" :value="num.name" :label="num.name">
                </vxe-option>
              </vxe-select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Table from './common/table.vue'
  import Table2 from './common/table2.vue'
  import Sample from './common/sample.vue'
  import {
    slider,
    slideritem
  } from "vue-concise-slider";
  export default {
    data() {
      return {
        category: 'activity',
        tackclass: [{
            name: "首页",
            href: "/home",
            imgs: require("../../assets/myactivity/3.png"),
          },
          {
            name: "上课",
            href: "/attend",
            imgs: require("../../assets/myactivity/4.png"),
          },
          {
            name: "活动",
            href: "/attend",
            imgs: require("../../assets/myactivity/1.png"),
          },
          {
            name: "作品集",
            href: "/showreel",
            imgs: require("../../assets/myactivity/2.png"),
          },
        ],
        activityStatus: '',
        Cover: true,
        value: '请选择',
        signShow: true,

        selectList: [
          '一年级一班',
          '一年级二班',
          '一年级三班',
          '一年级四班',
          '一年级五班',
          '一年级六班',
          '一年级七班',
          '一年级八班',
          '二年级六班',
          '一年级十班',
        ],
        CoverData: [{
          cover: ''
        }],
        activityId: '',
        options: {
          pagination: true,
          thresholdDistance: 100, // Sliding distance threshold
          thresholdTime: 300, // Sliding time threshold decision
          grabCursor: true, // Scratch style
          speed: 300, // Sliding speed
          freeze: true
        },
        classId: '',
        avatar1: '',
        succeed: false
      };
    },
    components: {
      Table,
      Table2,
      Sample,
      slider,
      slideritem,
    },
    methods: {
      //获取班级
      async getClass() {
        let resData = await this.$Api.Myactivity.getqueryTeacherClass();
        // console.log('获取班级', resData)
        this.selectList = resData.data;
        this.value = this.selectList[0].name;
        this.classId = this.selectList[0].id;
        // this.$Api.Home.getCourseInfo().then(res => {
        //   console.log('res', res)
        //   this.selectList = res.data;
        //   this.value = this.selectList[0].name;
        //   this.classId = this.selectList[0].id;
        // }).catch(err => {

        // })
      },
      classChange(value) {
        this.classId = this.selectList[this.IndexEvent(value, this.selectList)].id;
        // console.log('this.classId', this.classId)
      },
      IndexEvent(value, data) {
        let index = '';
        return index = data.findIndex(item => {
          return value.value === item.name || value.value === item.title;
        });
      },
      goback() {
        if (this.Cover) {
          this.$router.go(-1);
        } else {
          this.Cover = true;
        }

      },
      //排行榜
      activityCut(index, id) {
        this.activityStatus = index;
        this.activityId = id;
        // console.log('this.activityStatus', this.activityStatus)
        this.Cover = false;
        // console.log('活动id', id)
      },
      student() {
        this.signShow = true;
        this.allList = this.studentList;
      },
      classran() {
        this.signShow = false;
        this.allList = this.classList;
      },
      //排行榜
      site(value) {
        if (value == '首页') {
          this.$router.push({
            path: '/home',
          });

        } else if (value == '上课') {
          this.$router.push({
            path: '/home',
          });
        } else if (value == '作品集') {
          this.$router.push({
            // path: '/sampleReels',
            path: '/showreel',
          });
        }

      },

      async getActivityList() {
        let resData = await this.$Api.Myactivity.getActivityList();
        this.CoverData = resData.data;
        // console.log('this.CoverData', this.CoverData)
      },
      async getActivityrank() {
        let data = {
          type: 1
        }
        let resData = await this.$Api.Myactivity.getActivityrank(data);
        // console.log('排行榜', resData)
      },
      slide(data) {

      },
      slideNext() {
        this.$refs.slider.$emit('slideNext')
      },
      slidePre() {
        this.$refs.slider.$emit('slidePre')
      },
      errorimg(src) {
        this.avatar1 = 'https://yapi.cnuzi.com/uploads/' + src;
        let that = this;
        let img = new Image();
        img.src = this.avatar1;
        img.onload = function () {

          that.succeed = true;

        }
        img.onerror = function () {
          that.succeed = false;
        }
      },
    },
    mounted() {
      this.getActivityList();
      this.getClass();
      let that = this;
      this.$AccessPortrait.Portrait()
        .then(res => {
          that.errorimg(res);
        })

    }
  };
</script>
<style lang="less" scoped>
  @import "./less/activity";
</style>