<template>
      <div class='table'>
            <div class="tableHeight">
                  <vxe-table border="inner" :data="tableData" align="center" round :row-class-name="tableRowClassName"
                        :cell-class-name="cellClassName">
                        <vxe-table-column field="school" title="校内排名">
                              <template #default="{ row }">
                                    <img :src="rankingImg[row.row]" v-if="Number(row.row)<4" class="head">
                                    <span v-else>{{row.row}}</span>
                              </template>
                        </vxe-table-column>
                        <vxe-table-column field="className" title="班级">
                        </vxe-table-column>
                        <vxe-table-column field="teacherName" title="班主任">
                              <template #default="{ row }">
                                    <div class="director">
                                          <img src="../../../assets/myactivity/tea-woman.png">
                                          <span>{{row.teacherName}}</span>
                                    </div>
                              </template>
                        </vxe-table-column>
                        <vxe-table-column field="areaRow" title="区域排名"></vxe-table-column>
                        <vxe-table-column field="score" title="得分"></vxe-table-column>
                           <template v-slot:empty>
                              <span>
                                    <img src="../../../assets/empty.png" class="emptyImg">
                                    <p class="emptyText">期待同学们上传作品~</p>
                              </span>
                        </template>
                  </vxe-table>
            </div>
            <!-- <div class="pag flex-align-center">
                  <vxe-pager background :current-page.sync="page5.currentPage" :page-size.sync="page5.pageSize"
                        :total="page5.totalResult"
                        :layouts="['PrevPage', 'JumpNumber', 'NextPage','Total', 'FullJump']">
                  </vxe-pager>
            </div> -->
      </div>
</template>

<script>
      export default {
            data() {
                  return {
                        tableData2: [{
                                    id: 10003,
                                    school: "6",
                                    class: "二年级6班",
                                    director: "何颖",
                                    Regional: "124",
                                    score: "1314",
                              },
                              {
                                    id: 10001,
                                    school: "1",
                                    class: "五年级1班",
                                    director: "李海",
                                    Regional: "42",
                                    score: "1350",
                              },
                              {
                                    id: 10002,
                                    school: "2",
                                    class: "一年级2班",
                                    director: "李宁宁",
                                    Regional: "56",
                                    score: "1342",
                              },
                              {
                                    id: 10003,
                                    school: "3",
                                    class: "四年级3班",
                                    director: "赵晓霞",
                                    Regional: "79",
                                    score: "1340",
                              },
                              {
                                    id: 10003,
                                    school: "4",
                                    class: "五年级4班",
                                    director: "王金文",
                                    Regional: "92",
                                    score: "1338",
                              },
                              {
                                    id: 10003,
                                    school: "5",
                                    class: "三年级5班",
                                    director: "张宇",
                                    Regional: "108",
                                    score: "1320",
                              },
                              {
                                    id: 10003,
                                    school: "6",
                                    class: "二年级6班",
                                    director: "何颖",
                                    Regional: "124",
                                    score: "1314",
                              },
                        ],
                        tableData: [],
                        rankingImg: {
                              '1': require("../../../assets/myactivity/first.png"),
                              '2': require("../../../assets/myactivity/econd.png"),
                              '3': require("../../../assets/myactivity/thirdly.png"),
                        },
                        page5: {
                              currentPage: 1,
                              pageSize: 10,
                              totalResult: 6
                        },
                  }
            },
            props: ['value', 'activityId', 'className'],
            //方法
            methods: {
                  async getclassRank() {
                        let data = {
                              activityId: this.activityId,
                              classId: this.value,
                        }
                        let resData = await this.$Api.Myactivity.getclassRank(data);
                        this.tableData = resData.data.hundredRanks;
                        if (this.tableData.length == 0) return false;
                        // this.tableData.sort((a,b)=>{
                        //       return a.row-b.row
                        // })
                        if (resData.data.myTeacherName == null) return false;
                        let newData = {}
                        this.$set(newData, 'areaRow', resData.data.myAreaRow)
                        this.$set(newData, 'className', resData.data.myClassName)
                        this.$set(newData, 'row', resData.data.myRow)
                        this.$set(newData, 'score', resData.data.myScore)
                        this.$set(newData, 'teacherName', resData.data.myTeacherName)
                        this.tableData.unshift(newData)
                        // console.log('newData', newData)

                        // console.log('newData', newData)

                  },
                  tableRowClassName({
                        row,
                        rowIndex
                  }) {
                        if (rowIndex === 0 && this.className == row.className) return 'warning-row';
                  },
                  cellClassName({
                        column,
                  }) {
                        if (column.property === 'school' || column.property === 'Regional' || column.property ===
                              'score') {
                              return 'col-red'
                        }
                  },
            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('table2', this.value);
                  this.getclassRank();
            },
            watch: {
                  value(newVal, oldVal) {
                        this.getclassRank();
                        // console.log('oldVal', oldVal)
                        // console.log('newVal', newVal)
                  }
            }
      }
</script>
<style lang='less' scoped>
      @import "./less/table2";
</style>